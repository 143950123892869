@font-face {
  font-family: monotonRegular;
  src: url("assets/Monoton-Regular.ttf");
}

@font-face {
  font-family: sHPinscherRegular;
  src: url("assets/SHPinscher-Regular.otf")
}

@keyframes neon {
  0% {
    color: #00ff00;
  }
  50% {
    opacity: 0;
  }
}

* {
  margin: 0px;
  padding: 0px;
}

body {
  background: url(/images/rauletblurrrr.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100% 100%;
}

.App {
  width: 100%;
  height: 100%;
}

h1 {
  font-family: monotonRegular;
  font-size: 25vw;
  color: #00ff00;
  animation-name: neon;
  animation-duration: 0.3s;
  animation-iteration-count: infinite;
  animation-timing-function: steps(1);
  transition: none;
  cursor: pointer;
  font-weight: normal;
  z-index: 2;
}

h1.stopAnimation {
  animation: none;
}

h1:hover {
  animation: none;
}

nav {
  font-family: sHPinscherRegular;
  font-size: 2vw;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  z-index: 2;
}

nav a {
  color: #ff00aa;
  font-weight: bold;
  margin-right: 8px;
  margin-left: 8px;
  padding: 3px;
  border-radius: 10px;
  background-color: rgba(0, 255, 0, 0.3);
}

nav a:hover {
  background-color: #00ff00;
}

#background-video {
  width: 100%;
  height: 100%;
  position: fixed;
}

@media (max-width: 600px) {
  h1 {
    font-size: 30vw;
  }
  h2 {
    font-size: 7vw;
  }
  nav {
    font-size: 5vw;
  }
}
