#challenges {
    color: white;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    background-color: black;
}

.challengeCategory {
    background-color: rgb(34, 34, 34);
    font-family: sHPinscherRegular;
    font-size: 2vw;
    width: 90vw;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    z-index: 2;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
}

.challengePageTitle {
    background-color: rgb(34, 34, 34);
    font-family: sHPinscherRegular;
    font-size: 2vw;
    width: 90vw;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    z-index: 2;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
}

.modal {
    font-family: sHPinscherRegular;
    position: absolute;
    z-index: 1000;
    top: 10%;
    background-color: rgb(24, 24, 24);
    padding: 30px;
    margin: 30px;
    border-radius: 5px;
    width: 30vw;
}

.modal .challDesc {
    font-size: larger;
    padding: 10px;
}

.modal .postFlag {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-size: xx-large;
    vertical-align: middle;
}

.modal .postFlag input {
    background-color: grey;
    border: 1px solid black;
    border-radius: 5px;
    color: white;
    padding: 5px;
    font-size: large;
    min-width: 0;
    margin-right: 5px;
}

.modal .postFlag button {
    background-color: black;
    border: 1px solid grey;
    border-radius: 5px;
    color: white;
    padding: 5px;
    min-width: 0;
    cursor: pointer;
}

.modal .postFlag button:hover {
    background-color: #111;
}

.modal .closeButton {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.modal .closeButton button {
    background-color: rgb(24, 24, 24);
    color: white;
    font-size: x-large;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

#challenges nav button {
    font-family: sHPinscherRegular;
    font-size: xx-large;
    border: none;
    cursor: pointer;
    background-color: #00000045;
    border-radius: 25px;
    padding: 5px;
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.easy {
    color: rgb(0, 255, 0);
}
.medium {
    color: deepskyblue;
}
.hard {
    color: red;
}
.insane {
    color: #ff00aa;
}

@media screen and (max-width: 600px) {
    #challenges {
        font-size: smaller;
    }
    .modal .challDesc {
        font-size: small;
        padding: 1px;
    }
    .modal .postFlag {
        font-size: small;
        padding: 1px;
    }
}